import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TamilSchoolPage from "../components/schools/index"
const TamilSchool = () => {
  return (
    <Layout>
      <SEO
        title="Kentucky Tamil School"
        desc="Kentucky Tamil School"
        keywords="Kentucky - Tamil School"
      />
      <TamilSchoolPage />
    </Layout>
  )
}

export default TamilSchool
