import React from "react"
import SchoolTitle from "./title"
import { Link } from "gatsby"
const SchoolPage = () => {

  return (
    <>
      <div className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Tamil School</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Tamil School</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="team-section pt-70 pb-70">
        <div className="container">
          <div className="team-title-area">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="section-title text-left">
                  <span>
                    <i className="flaticon-support" />
                    Our Commitment
                  </span>
                  <h2>Tamil School</h2>

                </div>
              </div>
              <div className="col-lg-5">

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <p>
                Kentucky Tamil Sangam formed the Tamil School in 2006 with a group of volunteers to teach the students in Louisville to read, write and learn Tamil as language. Over the time Tamil school evolved as Louisville Tamil School (LTS) in 2016 after affiliating with California Tamil Academy (CTA). Tamil classes are conducted for all kids starting from age 4 to high school students at Lowe Elementary school in Louisville
              </p>
              <p className="mt-4">
                <strong>Contact:</strong>
              </p>
              <p>
                Principal: Felix Raj - <a href="tel:+1(502)-500-8542">502-500-8542</a>
              </p>
              <p>
                Vice Principal: Ramesh Babu Kumaravadivel - <a href="tel:+1(614)-439-6436">614-439-6436</a>
              </p>
              <p>
                Email Id: principals@louisivilletamilschool.com
              </p>
              <p>
                Website: <a href="http://www.louisvilletamilschool.com/" rel="noreferrer" target="_blank">http://www.louisvilletamilschool.com/</a>
              </p>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default SchoolPage
